import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Video from '../components/youtube-iframe';

const ModalYoutubePage = ({ location }) =>  (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className="youtubeModal">
        {modal ? (
          <Link style={{float:"right"}} to={closeTo}>
            Close
          </Link>
        ) : (
          <header>
            <h1>
              Video
            </h1>
          </header>
        )}

        <div style={{clear:"both"}}>
          <Video title="" autoplay="1" videoId={location.hash.substring(1)}  />
        </div>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default ModalYoutubePage
